// import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Grid,
  theme,
  Wrap,
  WrapItem,
  Button,
  IconButton,
  Textarea,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import Wheel from './Wheel'
import { useMemo, useState } from "react"
import { RepeatIcon } from '@chakra-ui/icons'

const MAX_SEGMENTS = 8;
enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
}
const numSegmentsToLabels = (x: number) =>
  Array.from({length: x}, (_, i) => (i + 1).toString());
const textInputToLabels = (text: string) =>
  text.trim().split('\n');
const colors: string[] = [
  '#FEEBC8',
  '#C4F1F9',
  '#FED7D7',
  '#B2F5EA',
  '#E9D8FD',
  '#BEE3F8',
  '#FED7E2',
  '#C6F6D5',
];
const segmentColors = [...colors, ...colors];

export const App = () => {
  const [numSegments, setNumSegments] = useState(6);
  const [textInput, setTextInput] = useState('Option 1\nOption 2');
  const [inputType, setInputType] = useState<InputType>(InputType.NUMBER);
  const [rerenderOnTextBlur, setRerenderOnTextBlur] = useState<boolean>(false);

  const numericSegmentLabels = numSegmentsToLabels(numSegments);
  const textSegmentLabels = textInputToLabels(textInput);

  const handleInputTypeChange = () => {
    if (inputType === InputType.NUMBER) {
      setInputType(InputType.TEXT);
    } else if (inputType === InputType.TEXT) {
      setInputType(InputType.NUMBER);
    }
  }

  const isTextValid = () => {
    const labels = textInputToLabels(textInput);
    return labels.length > 0 && labels.length <= MAX_SEGMENTS;
  }

  const rerenderOnValidTextInput = () => {
    if (isTextValid()) {
      setRerenderOnTextBlur(!rerenderOnTextBlur);
    }
  }

  const numericButtons = useMemo(() => {
    const buttons = [];
    for (let i = 2; i <= MAX_SEGMENTS; i++) {
      buttons.push(
        <WrapItem key={i}>
          <Button paddingX={10} onClick={() => setNumSegments(i)}>{i}</Button>
        </WrapItem>
      )
    }
  
    return (
      <Wrap justify='center'>
        {buttons}
      </Wrap>
    )
  }, []);

  const textArea = (
    <Box width={300}>
      <Textarea height={100}
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
        placeholder="Place each entry on a new line..."
        onBlur={rerenderOnValidTextInput}
        isInvalid={!isTextValid()}
      />
      {!isTextValid() && <Text fontSize='sm' color='#E53E3E'>You can have up to {MAX_SEGMENTS} categories...</Text>}
    </Box>
  )

  return <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={1}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <VStack spacing={8}>
          <Box width={320} height={320} overflow='hidden'>
            <Box margin='-140px'>
              <Wheel
                // @ts-ignore
                key={inputType === InputType.NUMBER ? numSegments : rerenderOnTextBlur}
                segments={inputType === InputType.NUMBER ? numericSegmentLabels : textSegmentLabels}
                segColors={segmentColors}
                onFinished={(winner: string) => {}}
                primaryColor='#EBF8FF'
                contrastColor='RGBA(0, 0, 0, 0.64)'
                buttonText='Spin'
                isOnlyOnce={false}
                size={150}
                upDuration={100}
                downDuration={600}
                fontFamily='Trebuchet MS'
              />
            </Box>
          </Box>
          <VStack height={200}>
            <IconButton
              onClick={handleInputTypeChange}
              variant='outline'
              colorScheme='blue'
              aria-label='Change input type'
              icon={<RepeatIcon />}
            />
            {inputType === InputType.NUMBER ? numericButtons : textArea}
          </VStack>
        </VStack>
      </Grid>
    </Box>
  </ChakraProvider>
}
